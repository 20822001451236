<template>
  <div :class="$style.box">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PaletteBox',
}
</script>

<style module>
.box {
  display: flex;
}
</style>