<template>
  <div
    :class="[$style.cell, {[$style.selected]: selected}]"
    :style="{'background-color': colorString}"
    @click="cellClick"
  >
    {{label}}
  </div>
</template>

<script>
import {AppController} from './AppController'

export default {
  name: 'PaletteCell',
  props: {
    'rgb': {
      type: Array
    },
    'label': {
      type: String,
      default: '',
    },
    'controller': {
      type: AppController,
    },
  },
  created() {
    const self = this;
    this.controller.addColorChangeCallback(()=>{
      self.updateState();
    });
    this.updateState();
  },
  computed: {
    colorString() {
      return `rgb(${this.rgb[0]}, ${this.rgb[1]}, ${this.rgb[2]})`;
    },
    colorUint32() {
      const [r, g, b] = this.rgb;
      const array8bit = new Uint8ClampedArray([r, g, b, 255]);
      return new Uint32Array(array8bit.buffer)[0];
    }
  },
  data: ()=>({
    selected: false,
  }),
  methods: {
    cellClick() {
      this.controller.changeColor(this.colorUint32);
    },
    updateState() {
      this.selected = (this.colorUint32 === this.controller.getCurrentColorUint32());
    },
  }
}
</script>

<style module>
.cell {
  width: 50px;
  height: 50px;
  border: 3px #888 solid;
}
.selected {
  border-style: dotted;
}
</style>