<template>
  <canvas
    :width="width"
    :height="height"
    ref="canvas"
    :class="$style.canvas"
    @mousedown="canvasMousedown"
    @mouseup="canvasMouseup"
    @mousemove="canvasMousemove"
    @touchstart.prevent="canvasTouchstart"
    @touchmove.prevent="canvasTouchmove"
    @touchend.prevent="canvasTouchend"
  />
</template>

<script>
import { AppController, ToolTarget } from './AppController'

export default {
  name: 'MyCanvas',
  props: {
    width: {
      type: Number,
      default: 640,
    },
    height: {
      type: Number,
      default: 480,
    },
    controller: {
      type: AppController,
    }
  },
  data: () => ({
    mousedown: false,
  }),
  mounted() {
    const ctx = this.$refs.canvas.getContext('2d');
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, this.width, this.height);
  },
  methods: {
    canvasMousedown(e) {
      this.mousedown = true;

      const ctx = this.$refs.canvas.getContext('2d');
      const tgt = new ToolTarget(this.controller, ctx);
      this.controller.tool.onMouseDown(tgt, e.offsetX, e.offsetY);
    },
    canvasMousemove(e) {
      if(this.mousedown) {
        const ctx = this.$refs.canvas.getContext('2d');
        const tgt = new ToolTarget(this.controller, ctx);
        this.controller.tool.onMouseMove(tgt, e.offsetX, e.offsetY);
      }
    },
    canvasMouseup() {
      this.mousedown = false;
      const ctx = this.$refs.canvas.getContext('2d');
      const tgt = new ToolTarget(this.controller, ctx);
      this.controller.tool.onMouseUp(tgt);
    },
    canvasTouchstart(e) {
      const x = Math.floor(e.touches[0].pageX - e.target.offsetLeft);
      const y = Math.floor(e.touches[0].pageY - e.target.offsetTop);

      this.mousedown = true;

      const ctx = this.$refs.canvas.getContext('2d');
      const tgt = new ToolTarget(this.controller, ctx);
      this.controller.tool.onSingleTouchStart(tgt, x, y);
      e.preventDefault();
    },
    canvasTouchmove(e) {
      const x = Math.floor(e.touches[0].pageX - e.target.offsetLeft);
      const y = Math.floor(e.touches[0].pageY - e.target.offsetTop);

      const ctx = this.$refs.canvas.getContext('2d');
      const tgt = new ToolTarget(this.controller, ctx);
      this.controller.tool.onSingleTouchMove(tgt, x, y);
      e.preventDefault();
    },
    canvasTouchend() {
      this.mousedown = false;
      const ctx = this.$refs.canvas.getContext('2d');
      const tgt = new ToolTarget(this.controller, ctx);
      this.controller.tool.onSingleTouchEnd(tgt);
    },
  }
}
</script>

<style module>
.canvas {
}
</style>
