<template>
  <MyCanvas
    :controller="controller"
    :width="width"
    :height="height"
  />
  <PaletteBox>
    <PaletteCell
      :rgb="[0, 0, 0]"
      :controller="controller"
    />
    <PaletteCell
      :rgb="[255, 0, 0]"
      :controller="controller"
    />
    <PaletteCell
      :rgb="[255, 128, 0]"
      :controller="controller"
    />
    <PaletteCell
      :rgb="[255, 255, 0]"
      :controller="controller"
    />
    <PaletteCell
      :rgb="[0, 255, 0]"
      :controller="controller"
    />
    <PaletteCell
      :rgb="[0, 255, 255]"
      :controller="controller"
    />
    <PaletteCell
      :rgb="[0, 0, 255]"
      :controller="controller"
    />
    <PaletteCell
      :rgb="[128, 0, 128]"
      :controller="controller"
    />
    <PaletteCell
      :rgb="[255, 255, 255]"
      :controller="controller"
    />
  </PaletteBox>
  <ToolButton
    label="ペン"
    :tool="penTool"
    :controller="controller"
  />
  <ToolButton
    label="ペン2"
    :tool="penTool2"
    :controller="controller"
  />
  <ToolButton
    label="アニメ塗りつぶし"
    :tool="animationFillTool"
    :controller="controller"
  />
</template>

<script>
import MyCanvas from './components/MyCanvas.vue'
import PaletteCell from './components/PaletteCell.vue'
import PaletteBox from './components/PaletteBox.vue'
import ToolButton from './components/ToolButton.vue'
import { AppController } from './components/AppController'
import { PenTool } from './components/PenTool'
import { AnimationFillTool } from './components/AnimationFillTool'

export default {
  name: 'App',
  components: {
    MyCanvas,
    PaletteCell,
    PaletteBox,
    ToolButton,
  },
  data: ()=>({
    controller: null,
    penTool: null,
    penTool2: null,
    width: 640,
    height: 480,
  }),
  created() {
    LoadSteppingFillScript();
    this.controller = new AppController(this.width, this.height);
    this.penTool = new PenTool(this.controller, 'PenTool', 10);
    this.penTool2 = new PenTool(this.controller, 'PenTool2', 1);
    this.animationFillTool = new AnimationFillTool(this.controller, 'AnimationFillTool');
    this.controller.setTool(this.penTool);
  }
}

function LoadSteppingFillScript() {
  const path = process.env.VUE_APP_STEPPING_FILL_SCRIPT_PATH;
  console.log(path);
  if(path) {
    const el = document.createElement('script');
    el.setAttribute('src', path);
    document.head.appendChild(el);
  }
}
</script>

<style module>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body {
  background: #ccd;
}
</style>
