import { ToolInterface } from './ToolInterface'

class CanvasProxy {
  constructor(context, width, height, safeColor, color) {
    this.context = context;
    this.width = width;
    this.height = height;
    this.safeColor = safeColor;
    this.refImageData = this.context.getImageData(0, 0, width, height);
    this.refUint32Array = new Uint32Array(this.refImageData.data.buffer);
    this.tgtImageData = this.context.getImageData(0, 0, width, height);
    this.tgtUint32Array = new Uint32Array(this.tgtImageData.data.buffer);
    this.color = color;
  }
  isWall(x, y) {
    if(x < 0 || this.width <= x) return true;
    if(y < 0 || this.height <= y) return true;
    const pixelColor = this.refUint32Array[y*this.width+x];
    if(pixelColor !== this.safeColor){
      return true;
    }
    return false;
  }
  fillPoint(x, y) {
    this.tgtUint32Array[y*this.width+x] = this.color;
  }
  getWidth() {return this.width;}
  getHeight() {return this.height;}
  applyToTarget() {
    this.context.putImageData(this.tgtImageData, 0, 0);
  }
}

class AnimationProcess {
  constructor(fillProcessor, canvas) {
    this.fillProcessor = fillProcessor;
    this.canvas = canvas;
    this.distance = 0;
  }
  proceed(stepDistance) {
    this.distance += stepDistance;
    this.fillProcessor.proceedToDistance(this.distance);
    this.canvas.applyToTarget();
  }
  isComplete() {
    return this.fillProcessor.isComplete();
  }
}

export class AnimationFillTool extends ToolInterface {
  constructor(controller, name) {
    super();
    this.controller = controller;
    this.name = name;
    this.processing = false;
  }
  onMainButtonDown(target, x, y) {
    if(this.processing) return;
    const imagedata = target.context.getImageData(x, y, 1, 1);
    const width = this.controller.getWidth();
    const height = this.controller.getHeight();
    const safeColor = new Uint32Array(imagedata.data.buffer)[0];
    const color = this.controller.getCurrentColorUint32();
    const canvas = new CanvasProxy(target.context, width, height, safeColor, color);
    const processor = new window.SteppingFillProcessor(canvas, x, y);
    const process = new AnimationProcess(processor, canvas);
    const stepDistance = 10;
    let adjustedStepDistance = stepDistance;
    let lastFrame = Date.now();
    const self = this;
    self.processing = true;
    const intervalFunc = ()=>{
      process.proceed(adjustedStepDistance);
      if(process.isComplete()) {
        self.processing = false;
      } else {
        const now = Date.now();
        const wait_ms = lastFrame+16-now;
        if(wait_ms < 0) {
          adjustedStepDistance = Math.max(adjustedStepDistance-1, 1);
        } else {
          adjustedStepDistance = Math.min(adjustedStepDistance+1, stepDistance);
        }
        lastFrame = now;
        console.log(adjustedStepDistance, wait_ms);
        setTimeout(intervalFunc, Math.max(wait_ms, 0));
      }
    };
    intervalFunc();
  }
  getToolName() {
    return this.name;
  }
}