<template>
  <button @click="click" :class="{[$style.selected]: selected}">{{label}}</button>
</template>

<script>
import { AppController } from './AppController'
import { ToolInterface } from './ToolInterface'

export default {
  name: 'ToolButton',
  props: {
    label: {
      type: String,
    },
    tool: {
      type: ToolInterface,
    },
    controller: {
      type: AppController,
    },
  },
  data: ()=>({
    selected: false,
  }),
  created() {
    const self = this;
    this.controller.addToolChangeCallback(()=>{
      self.updateState();
    });
    this.updateState();
  },
  methods: {
    click() {
      this.controller.setTool(this.tool);
    },
    updateState() {
      this.selected = (this.tool.getToolName() === this.controller.getCurrentTool().getToolName());
    },
  }
}
</script>

<style module>
.selected {
  background: #ffc;
}
</style>