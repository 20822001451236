export class ToolTarget {
  constructor(controller, context) {
    this.controller = controller;
    this.context = context;
  }
  getContext() {
    return this.context;
  }
}

export class AppController {
  constructor(width, height) {
    this.rgbArray = new Uint8ClampedArray([0,0,0,255]);
    this.colorUintArray = new Uint32Array(this.rgbArray.buffer);
    this.colorChangeCallbacks = [];
    this.toolChangeCallbacks = [];
    this.tool = null;
    this.width = width;
    this.height = height;
  }
  changeColor(color) {
    this.colorUintArray[0] = color;
    this.colorChangeCallbacks.forEach((callback) => {callback(color);});
  }
  addColorChangeCallback(callback) {
    this.colorChangeCallbacks.push(callback);
  }
  getCurrentColorString() {
    const r = this.rgbArray[0];
    const g = this.rgbArray[1];
    const b = this.rgbArray[2];
    return `rgb(${r}, ${g}, ${b})`;
  }
  getCurrentColorUint32() {
    return this.colorUintArray[0];
  }
  addToolChangeCallback(callback) {
    this.toolChangeCallbacks.push(callback);
  }
  setTool(tool) {
    this.tool = tool;
    this.toolChangeCallbacks.forEach((callback) => {callback();});
  }
  getCurrentTool() {
    return this.tool;
  }
  getWidth() {
    return this.width;
  }
  getHeight() {
    return this.height;
  }
}