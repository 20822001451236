export class ToolInterface {
  constructor() {}
  onMouseDown(tgt, x, y) {
    this.onMainButtonDown(tgt, x, y);
  }
  onMouseMove(tgt, x, y) {
    this.onCursorMove(tgt, x, y);
  }
  onMouseUp(tgt) {
    this.onMainButtonUp(tgt);
  }
  onSingleTouchStart(tgt, x, y) {
    this.onMainButtonDown(tgt, x, y);
  }
  onSingleTouchMove(tgt, x, y) {
    this.onCursorMove(tgt, x, y);
  }
  onSingleTouchEnd(tgt) {
    this.onMainButtonUp(tgt);
  }
  onMainButtonDown() {}
  onCursorMove() {}
  onMainButtonUp() {}
  getToolName() {}
}