import { ToolInterface } from './ToolInterface'

export class PenTool extends ToolInterface {
  constructor(controller, name, boldness) {
    super();
    this.controller = controller;
    this.name = name;
    this.boldness = boldness;
    this.mousedown = false;
    this.lastPos = null;
  }
  onMainButtonDown(target, x, y) {
    this.mousedown = true;
    this.drawCircle(target, x, y);
    this.lastPos = [x, y];
  }
  onCursorMove(target, x, y) {
    if(this.mousedown) {
      this.drawCircleContinuously(target, this.lastPos[0], this.lastPos[1], x, y);
      this.lastPos = [x, y];
    }
  }
  onMainButtonUp() {
    this.mousedown = false;
  }
  drawCircleContinuously(target, x1, y1, x2, y2) {
    const [dx,sx] = x1<=x2 ? [x2-x1, -1] : [x1-x2, 1];
    const [dy,sy] = y1<=y2 ? [y2-y1, -1] : [y1-y2, 1];
    if(dx<dy) {
      let n = 0;
      let x = x2;
      for(let y=y2; y != y1; y+=sy) {
          this.drawCircle(target, x, y);
        n+=dx;
        if(dy<=n) {
          x+=sx;
          n-=dy;
        }
      }
    } else {
      let n = 0;
      let y = y2;
      for(let x=x2; x != x1; x+=sx) {
      this.drawCircle(target, x, y);
        n+=dy;
        if(dx<=n) {
          y+=sy;
          n-=dx;
        }
      }
    }
  }
  drawCircle(target, x0, y0) {
    const ctx = target.getContext();

    const top = y0 - Math.floor(this.boldness/2);
    const left = x0 - Math.floor(this.boldness/2);
    const imageData = ctx.getImageData(left, top, this.boldness, this.boldness);
    const pixelBuffer = new Uint32Array(imageData.data.buffer);
    const color = this.controller.getCurrentColorUint32();

    for(let ry=0; ry<this.boldness; ry++) {
        for(let rx=0; rx<this.boldness; rx++) {
          if( (2*rx-this.boldness)**2 + (2*ry-this.boldness)**2 <= 4*this.boldness ) {
            pixelBuffer[ry*this.boldness+rx] = color;
          }
        }
    }

    ctx.putImageData(imageData, left, top);
  }
  getToolName() {
    return this.name;
  }
}